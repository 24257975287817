.preloader {
  &__wrapper {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $black;
    z-index: 1000;
    padding: 0 1rem;
  }

  &__lottie {
    max-width: convertToRem(500px);
  }
}
