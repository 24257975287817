@mixin container {
  width: 100%;
  padding: 0 24px;
  max-width: 84.375rem;
  margin: 0 auto;
}

@function convertToRem($value) {
  $remValue: calc($value / 16px);
  @return ($remValue * 1rem);
}
