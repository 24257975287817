.feature {
  padding-top: convertToRem(70px);

  &-header {
    @include container();
    line-height: 1.06;

    &__text {
      h2 {
        font-weight: $font-weight-4;
        font-size: convertToRem(40px);
        text-transform: capitalize;
        color: $white;
      }

      p {
        font-weight: $font-weight-2;
        font-size: convertToRem(14px);
        line-height: 1.27;
        margin: 0.375rem 0 convertToRem(30px);
        text-transform: capitalize;
        color: $white;
      }

      div {
        a {
          max-width: convertToRem(144px);
          display: block;
          font-size: 0.625rem;
          font-family: "Satoshi";
          font-weight: $font-weight-4;
          text-align: center;
          background-color: $white;
          color: $black;
          padding: 0.875rem 0;
          line-height: 1;
          margin-top: 1.15rem;
          border-radius: 1.5rem;
        }
      }
    }

    &__image {
      img {
        max-height: convertToRem(300px);
        margin: 0 auto;
      }
    }
  }

  &-image {
    user-select: none;
    pointer-events: none;
    margin-top: convertToRem(46px);
    position: relative;

    img {
      width: 100%;
      height: auto;
    }

    &__desktop {
      display: none;
    }

    &__gradient {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 50%;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, $black 69.79%);
    }
  }

  @media screen and (min-width: 1024px) {
    padding-top: 96px;

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &__text {
        h2 {
          font-size: 72px;
        }

        p {
          margin: 0.5rem 0 0;
          font-size: 20px;
        }

        div {
          max-width: convertToRem(157px);
          a {
            font-size: 0.8375rem;
            padding: 1rem 0;
          }
        }
      }
    }

    &-image {
      margin-top: convertToRem(150px);

      &__mobile {
        display: none;
      }
      &__desktop {
        display: block;
      }
    }
  }
}
