@import "https://res.cloudinary.com/fluidcoins/raw/upload/v1637590555/Typography/ClashDisplay/ClashDisplay-Variable-FontFace_aw31h4.css";
@import "https://res.cloudinary.com/fluidcoins/raw/upload/v1641989311/Typography/Creato-Display/font-face_uiwzez.css";
@import "https://res.cloudinary.com/fluidcoins/raw/upload/v1654893829/Typography/MonumentExtended/stylesheet_ooztwi.css";
@import "https://res.cloudinary.com/fluidcoins/raw/upload/v1655491128/Typography/Satoshi/satoshi_cixnim.css";
a {
  color: #000;
  text-decoration: none;
}

*, :before, :after {
  box-sizing: border-box;
  font-family: ClashDisplay;
}

* {
  margin: 0;
}

html, body {
  height: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
  background-color: #000;
  line-height: 1.5;
}

img, picture, video, canvas, svg {
  max-width: 100%;
  display: block;
}

input, button, textarea, select {
  font: inherit;
}

p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}

#root, #__next {
  isolation: isolate;
}

.sr-only {
  width: 1px;
  height: 1px;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

::-webkit-scrollbar {
  display: none;
}

html.has-scroll-smooth {
  overflow: hidden;
}

html.has-scroll-dragging {
  user-select: none;
}

.has-scroll-smooth body {
  overflow: hidden;
}

.has-scroll-smooth [data-scroll-container] {
  min-height: 100vh;
}

[data-scroll-direction="horizontal"] [data-scroll-container] {
  height: 100vh;
  white-space: nowrap;
  display: inline-block;
}

[data-scroll-direction="horizontal"] [data-scroll-section] {
  vertical-align: top;
  white-space: nowrap;
  height: 100%;
  display: inline-block;
}

.c-scrollbar {
  width: .6875rem;
  height: 100%;
  transform-origin: 100%;
  opacity: 0;
  transition: transform .3s, opacity .3s;
  position: absolute;
  top: 0;
  right: 0;
}

.c-scrollbar:hover {
  transform: scaleX(1.45);
}

.c-scrollbar:hover, .has-scroll-scrolling .c-scrollbar, .has-scroll-dragging .c-scrollbar {
  opacity: 1;
}

[data-scroll-direction="horizontal"] .c-scrollbar {
  width: 100%;
  height: .625rem;
  top: auto;
  bottom: 0;
  transform: scaleY(1);
}

[data-scroll-direction="horizontal"] .c-scrollbar:hover {
  transform: scaleY(1.3);
}

.c-scrollbar_thumb {
  opacity: .5;
  width: .4375rem;
  cursor: -webkit-grab;
  cursor: grab;
  background-color: #000;
  border-radius: .625rem;
  margin: .125rem;
  position: absolute;
  top: 0;
  right: 0;
}

.has-scroll-dragging .c-scrollbar_thumb {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

[data-scroll-direction="horizontal"] .c-scrollbar_thumb {
  bottom: 0;
  right: auto;
}

.footer {
  width: 100%;
  max-width: 84.375rem;
  margin: 0 auto;
  padding: 0 24px;
}

.footer > div {
  max-width: 832px;
  padding: 89px 0 60px;
}

.footer * {
  font-family: CreatoDisplay;
}

.footer-header {
  margin-bottom: 70px;
  padding: 0 1rem;
}

.footer-header > div {
  align-items: center;
  display: inline-flex;
}

.footer-header__logo {
  margin-right: 10px;
}

.footer-header__title {
  color: #fff;
  font-family: ClashDisplay;
  font-size: 20px;
  font-weight: 500;
  line-height: 93%;
}

.footer-header__mail {
  color: #fff;
  margin: 38px 0 36px;
  font-size: 14px;
  line-height: 17px;
  display: block;
}

.footer-header__address {
  color: #fff;
  font-size: 14px;
  font-style: normal;
  line-height: 27px;
}

.footer-nav {
  width: 100%;
  max-width: 600px;
  row-gap: 76px;
  margin-bottom: 94px;
  padding: 0 1rem;
  display: grid;
}

.footer-nav__link-container {
  row-gap: 32px;
  display: grid;
}

.footer-nav__link {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  display: block;
}

.footer-nav__title {
  color: #888;
  margin-bottom: 20px;
  font-size: 17px;
  font-weight: 700;
  line-height: 20px;
}

.footer-copyright {
  text-align: center;
  color: #fff;
  padding-bottom: 60px;
  font-size: 1rem;
  font-weight: 500;
  line-height: 19px;
}

@media screen and (min-width: 768px) {
  .footer > div {
    justify-content: space-between;
    padding-top: 24px;
    display: flex;
  }

  .footer-header {
    width: 100%;
  }

  .footer-nav {
    grid-template-columns: repeat(2, 1fr);
  }

  .footer-nav > :last-child {
    justify-self: center;
  }

  .footer-copyright {
    padding-bottom: 96px;
  }
}

header {
  z-index: 1;
  position: relative;
}

header nav {
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 40px 0 0;
  display: flex;
  position: absolute;
}

header nav > a {
  background: #fff;
  border-radius: 40px;
  align-items: center;
  padding: 4px 8px 4px 4px;
  display: inline-flex;
}

header nav > a h2 {
  color: #000;
  font-family: ClashDisplay;
  font-size: 20px;
  font-weight: 500;
  line-height: 1;
}

header nav > a div {
  background-color: #000;
  border-radius: 14px;
  margin-right: 10px;
  padding: 8px 0;
}

header nav > div {
  display: none;
}

@media screen and (min-width: 768px) {
  header nav {
    padding: 90px 105px 0 0;
  }

  header nav:before {
    content: "";
    flex: 0 0 50%;
    display: block;
  }

  header nav > div {
    background-color: #fff;
    border-radius: 26.5px;
    align-items: center;
    margin-left: auto;
    padding: 12.5px 16px;
    display: inline-flex;
  }

  header nav > div div {
    height: 22px;
    border-left: 1px solid #e7e7e7;
    margin: 0 12px;
  }
}

.download {
  background-image: url("https://res.cloudinary.com/fluidcoins/image/upload/v1654873769/useflip-landing/hero-texture._bp4dnu.svg");
}

.download > div {
  min-height: 75vh;
  position: relative;
  overflow: hidden;
}

.download-main {
  z-index: 1;
  position: relative;
}

.download-main__header {
  padding-top: 9.75rem;
  position: relative;
}

.download-main__header > div {
  position: relative;
}

.download-main__header * {
  min-height: 0;
  text-align: center;
  font-family: Monument Extended, sans-serif;
  font-size: clamp(42px, 11.2vw, 128px);
  font-weight: 800;
  line-height: 1.21;
}

.download-main__header__title {
  color: #aeff00;
  z-index: 10;
  position: relative;
}

.download-main__header__title--effect {
  height: calc(clamp(42px, 11.2vw, 128px) / 2.22222);
  background: #fff;
  -webkit-background-clip: text;
  -webkit-text-stroke: 2px transparent;
  display: block;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.download-main__header__title--effect:nth-child(n+1) {
  z-index: -1;
  top: -23%;
}

.download-main__header__title--effect:nth-child(n+2) {
  z-index: -2;
  top: -46%;
}

.download-main__header__effect img {
  position: absolute;
}

.download-main__link-container {
  justify-content: center;
  align-items: center;
  margin-top: 1.25rem;
  display: flex;
}

.download-main__link-container > :last-child {
  margin-left: .5rem;
}

.download-main__link-item {
  height: 2.3125rem;
  width: 100%;
  max-width: 7rem;
  background-color: #fff;
  border-radius: 2rem;
  justify-content: center;
  align-items: center;
  padding: .25rem 0;
  font-size: .625rem;
  font-weight: 700;
  line-height: 1.46;
  display: inline-flex;
}

.download-main__link-item > span {
  margin-left: .25rem;
  font-family: Satoshi;
}

.download-texture {
  height: 52%;
  width: 100%;
  background-image: url("https://res.cloudinary.com/fluidcoins/image/upload/v1654871876/useflip-landing/download-texture_mctxc5.svg");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: 200%;
  position: absolute;
  bottom: 0;
}

.download-effect--coin img {
  max-width: 9.75rem;
  width: 2.625rem;
  bottom: 40%;
  left: 5%;
}

.download-effect--double-coin--desktop {
  display: none;
}

.download-effect--double-coin--mobile img {
  width: 3.4375rem;
  bottom: 0%;
  right: 0%;
}

.download-effect--goggles img {
  max-width: 4.875rem;
  width: 3rem;
  bottom: 45%;
  left: 55%;
}

@media screen and (min-width: 768px) {
  .download > div {
    min-height: min(40.5rem, 90vh);
  }

  .download-main__link-item {
    max-width: 9.75rem;
    height: 3.5rem;
  }

  .download-main__link-item span {
    font-size: .875rem;
  }

  .download-texture {
    height: 72%;
    background-size: 100%;
  }

  .download-effect--coin img {
    width: 11%;
    bottom: 20%;
    left: 3%;
  }

  .download-effect--double-coin img {
    width: 14%;
    bottom: 0%;
    right: -2%;
  }

  .download-effect--goggles img {
    width: 11%;
    bottom: 60%;
    left: 60%;
  }
}

@media screen and (min-width: 1024px) {
  .download > div {
    min-height: max(62.5rem, 90vh);
  }

  .download-main__header {
    padding-top: 16.25rem;
  }

  .download-effect--coin img {
    bottom: 20%;
    left: 3%;
  }

  .download-effect--double-coin--desktop {
    display: block;
  }

  .download-effect--double-coin--mobile {
    display: none;
  }

  .download-effect--double-coin img {
    width: 31%;
    bottom: 0%;
    right: -2%;
  }

  .download-effect--goggles img {
    bottom: 60%;
    left: 60%;
  }
}

@media screen and (min-width: 1360px) {
  .download-effect--coin img {
    left: 8%;
  }

  .download-effect--double-coin img {
    right: 6%;
  }
}

.preloader__wrapper {
  width: 100%;
  height: 100vh;
  z-index: 1000;
  background-color: #000;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
  display: flex;
  position: fixed;
  top: 0;
}

.preloader__lottie {
  max-width: 31.25rem;
}

.feature {
  padding-top: 4.375rem;
}

.feature-header {
  width: 100%;
  max-width: 84.375rem;
  margin: 0 auto;
  padding: 0 24px;
  line-height: 1.06;
}

.feature-header__text h2 {
  text-transform: capitalize;
  color: #fff;
  font-size: 2.5rem;
  font-weight: 700;
}

.feature-header__text p {
  text-transform: capitalize;
  color: #fff;
  margin: .375rem 0 1.875rem;
  font-size: .875rem;
  font-weight: 500;
  line-height: 1.27;
}

.feature-header__text div a {
  max-width: 9rem;
  text-align: center;
  color: #000;
  background-color: #fff;
  border-radius: 1.5rem;
  margin-top: 1.15rem;
  padding: .875rem 0;
  font-family: Satoshi;
  font-size: .625rem;
  font-weight: 700;
  line-height: 1;
  display: block;
}

.feature-header__image img {
  max-height: 18.75rem;
  margin: 0 auto;
}

.feature-image {
  user-select: none;
  pointer-events: none;
  margin-top: 2.875rem;
  position: relative;
}

.feature-image img {
  width: 100%;
  height: auto;
}

.feature-image__desktop {
  display: none;
}

.feature-image__gradient {
  width: 100%;
  height: 50%;
  background: linear-gradient(#0000 0%, #000 69.79%);
  position: absolute;
  bottom: 0;
}

@media screen and (min-width: 1024px) {
  .feature {
    padding-top: 96px;
  }

  .feature-header {
    justify-content: space-between;
    align-items: center;
    display: flex;
  }

  .feature-header__text h2 {
    font-size: 72px;
  }

  .feature-header__text p {
    margin: .5rem 0 0;
    font-size: 20px;
  }

  .feature-header__text div {
    max-width: 9.8125rem;
  }

  .feature-header__text div a {
    padding: 1rem 0;
    font-size: .8375rem;
  }

  .feature-image {
    margin-top: 9.375rem;
  }

  .feature-image__mobile {
    display: none;
  }

  .feature-image__desktop {
    display: block;
  }
}

.savings {
  background-color: #493af0;
  align-items: center;
  padding: 5rem 0 2.5rem;
  display: flex;
  position: relative;
  overflow: hidden;
}

.savings-main {
  width: 100%;
  max-width: 84.375rem;
  z-index: 1;
  margin: 0 auto;
  padding: 0 24px;
  position: relative;
}

.savings-main-left__tag {
  width: fit-content;
  background-color: #f89cde;
  border-radius: 50%;
  padding: 1rem 1.5rem;
  transform: rotate(-8.32deg);
}

.savings-main-left__tag p {
  letter-spacing: .07em;
  text-transform: capitalize;
  color: #d2fa7c;
  font-family: Monument Extended, sans-serif;
  font-weight: 800;
  line-height: 1.01;
}

.savings-main-left__title {
  margin-top: 1.5rem;
}

.savings-main-left__title h2 {
  text-transform: capitalize;
  color: #fff;
  font-family: Monument Extended, sans-serif;
  font-size: 2.25rem;
  font-weight: 800;
  line-height: 1.06;
}

.savings-main-left__features {
  gap: 2.75rem;
  margin-top: 4.875rem;
  display: grid;
}

.savings-main-left__features div h3 {
  text-transform: capitalize;
  color: #fff;
  margin-top: .75rem;
  font-family: CreatoDisplay;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.3;
}

.savings-main-left__features div br {
  display: none;
}

.savings-main__image {
  margin-top: 8rem;
}

.savings-main__image img {
  margin: 0 auto;
}

.savings__hover-reveal {
  display: none;
}

.savings-texture {
  width: 100%;
  height: min(100vh, 60rem);
  background-image: url("https://res.cloudinary.com/fluidcoins/image/upload/v1655063774/useflip-landing/saving-texture-mobile_efkj66.svg");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
}

@media screen and (min-width: 768px) {
  .savings-main__image img {
    height: calc(100vh - 7rem);
  }
}

@media screen and (min-width: 1024px) {
  .savings {
    min-height: 100vh;
    padding: 3.5rem 0;
  }

  .savings-main {
    justify-content: space-between;
    align-items: center;
    padding-right: 5rem;
    display: flex;
  }

  .savings-main-left__tag {
    border-radius: 50%;
    padding: 1.25rem 2rem;
    transform: rotate(-8.32deg);
  }

  .savings-main-left__tag P {
    font-size: 2.625rem;
  }

  .savings-main-left__tag__title {
    margin-top: 3rem;
  }

  .savings-main-left__tag__title h2 {
    font-size: 2.75rem;
  }

  .savings-main-left__features {
    grid-template-columns: repeat(2, minmax(18.75rem, 1fr));
    gap: 56px;
  }

  .savings-main-left__features div h3 {
    font-size: 1.25rem;
  }

  .savings-main-left__features div h3 br {
    display: block;
  }

  .savings-main__image {
    margin-top: 0;
  }

  .savings-main__image img {
    max-height: 54.375rem;
  }

  .savings-texture {
    background-image: url("https://res.cloudinary.com/fluidcoins/image/upload/v1654882240/useflip-landing/savings-texture_k0rpla.svg");
    background-size: cover;
    background-position: unset;
    left: 0;
  }

  .savings__hover-reveal {
    width: 13.75rem;
    height: 13.75rem;
    display: block;
    position: absolute;
    top: 20%;
  }

  .savings__hover-reveal__inner__img {
    width: 100%;
    height: 100%;
    pointer-events: none;
    user-select: none;
  }
}

@media screen and (min-width: 1536px) {
  .savings-texture {
    height: 100vh;
  }
}

.spending {
  min-height: 100vh;
  background-color: #fff;
  align-items: center;
  padding: 4.5rem 0 2.375rem;
  display: flex;
  position: relative;
}

.spending > div {
  width: 100%;
}

.spending__main {
  width: 100%;
  max-width: 84.375rem;
  width: 100%;
  z-index: 1;
  margin: 0 auto;
  padding: 0 1.5rem;
  position: relative;
}

.spending__main > div {
  flex-direction: column-reverse;
  display: flex;
}

.spending__main__right__tag {
  width: fit-content;
  background: #293ef8;
  border: .4375rem solid #fff;
  border-radius: 3.8125rem;
  padding: 1.125rem;
  transform: rotate(-2.87deg);
}

.spending__main__right__tag p {
  text-transform: capitalize;
  color: #fff;
  font-family: Monument Extended, sans-serif;
  font-size: 1.0625rem;
  font-weight: 800;
  line-height: 1.4;
}

.spending__main__right__title {
  margin-top: 2rem;
}

.spending__main__right__title h2 {
  text-transform: capitalize;
  color: #000;
  font-family: Monument Extended, sans-serif;
  font-size: 2.25rem;
  font-weight: 800;
  line-height: 106%;
}

.spending__main__right__title br {
  display: none;
}

.spending__main__right__features {
  max-width: 22.8125rem;
  margin-top: 4rem;
}

.spending__main__right__features > div:first-child {
  margin-bottom: 2.875rem;
}

.spending__main__right__features div:last-child {
  margin-bottom: 5.75rem;
}

.spending__main__right__features div h2 {
  color: #000;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 93%;
}

.spending__main__right__features div p {
  color: #888;
  margin-top: 1.25rem;
  font-size: .9375rem;
  font-weight: 500;
  line-height: 120%;
}

.spending__main__right__features div a {
  text-align: center;
  color: #fff;
  max-width: 7.375rem;
  background-color: #000;
  border-radius: 1.5rem;
  margin-top: 2.5rem;
  padding: .875rem 0;
  font-family: Satoshi;
  font-size: .625rem;
  font-weight: 700;
  line-height: 1;
  display: block;
}

.spending__main__image img {
  margin: 0 auto;
}

.spending__hover-reveal {
  display: none;
}

.spending__texture {
  width: 100%;
  height: 70%;
  background-image: url("https://res.cloudinary.com/fluidcoins/image/upload/v1655110856/useflip-landing/spending-texture-mobile_rsjijl.svg");
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  bottom: 0;
  left: 0;
}

@media screen and (min-width: 768px) {
  .spending__main__right__tag {
    border: .4375rem solid #fff;
    border-radius: 3.8125rem;
    padding: 26px;
    transform: rotate(-8.95deg);
  }

  .spending__main__right__tag p {
    font-size: 1.375rem;
  }

  .spending__main__right__title {
    font-size: 2.75rem;
  }

  .spending__main__right__title br {
    display: block;
  }

  .spending__main__right__features {
    margin-top: 2.75rem;
  }

  .spending__main__right__features > div:first-child {
    margin-bottom: 3.25rem;
  }

  .spending__main__right__features > div h2 {
    font-size: 1.25rem;
  }

  .spending__main__right__features > div p {
    color: #000;
    margin-top: 1.5rem;
    font-size: 1.125rem;
  }

  .spending__main__right__features > div a {
    max-width: 10.75rem;
    border-radius: 2.5rem;
    margin-top: 2.75rem;
    padding: 1.19rem 0;
    font-size: .875rem;
  }
}

@media screen and (min-width: 768px) {
  .spending__main__image img {
    height: calc(100vh - 7rem);
  }
}

@media screen and (min-width: 1024px) {
  .spending__main__right__features > div:last-child {
    margin-bottom: 0;
  }

  .spending__main > div {
    flex-direction: row;
    justify-content: space-between;
    padding-left: 1.25rem;
    padding-right: 12.8125rem;
  }

  .spending__main__image img {
    max-height: 54.375rem;
  }

  .spending__texture {
    height: 37vw;
    background-image: url("https://res.cloudinary.com/fluidcoins/image/upload/v1654886746/useflip-landing/spending-texture_p1msoz.svg");
  }

  .spending__hover-reveal {
    user-select: none;
    pointer-events: none;
    width: 13.75rem;
    height: 9.375rem;
    display: block;
    position: absolute;
    top: 20%;
  }

  .spending__hover-reveal__inner__img {
    width: 100%;
    height: 100%;
  }
}

@media screen and (min-width: 1024px) and (min-height: 800px) {
  .spending__texture {
    height: 45vw;
  }
}

.seamlessly > div {
  width: 100%;
  max-width: 84.375rem;
  margin: 0 auto;
  padding: 0 1rem;
}

.seamlessly__header {
  padding: 5rem 0 3.5rem;
}

.seamlessly__header h2 {
  text-align: center;
  text-transform: capitalize;
  color: #aeff00;
  font-family: Monument Extended, sans-serif;
  font-size: clamp(2.25rem, 4.76vw, 4.5rem);
  font-weight: 800;
  line-height: 1.06;
}

.seamlessly__contents-container {
  row-gap: 2.75rem;
  display: grid;
}

.seamlessly__content {
  min-height: 21.5625rem;
  border-radius: 1.625rem;
  flex-direction: column;
  justify-content: space-between;
  display: flex;
  overflow: hidden;
}

.seamlessly__content img {
  width: 100%;
}

.seamlessly__content__header {
  padding: 1.5rem;
}

.seamlessly__content__header h3 {
  text-transform: capitalize;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 106%;
}

.seamlessly__content__body {
  height: 100%;
  margin-top: .25rem;
  padding: 0 .5rem .5rem;
}

.seamlessly__content__body > div {
  height: 100%;
  background: #ffffff61;
  border: .8px solid #ffffff52;
  border-radius: 1.375rem;
  position: relative;
}

.seamlessly__content__body > div img {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.seamlessly__content__body__coin {
  max-width: 90px;
  bottom: 7.5%;
}

.seamlessly__content__body__qr-code {
  max-width: 90%;
  top: -28%;
}

.seamlessly__content__body__earth {
  top: -30%;
}

.seamlessly__gradient--flip-tag {
  background-image: url("https://res.cloudinary.com/fluidcoins/image/upload/v1655121029/useflip-landing/flip-tag-radient_py1zmu.webp");
  background-repeat: no-repeat;
  background-size: cover;
}

.seamlessly__gradient--seamlessly {
  background-image: url("https://res.cloudinary.com/fluidcoins/image/upload/v1654873477/useflip-landing/seamless-gradient_hyhzlw.webp");
  background-repeat: no-repeat;
  background-size: cover;
}

.seamlessly__gradient--payouts {
  background-image: url("https://res.cloudinary.com/fluidcoins/image/upload/v1654873477/useflip-landing/payouts-gradient_tzgijb.webp");
  background-repeat: no-repeat;
  background-size: cover;
}

@media screen and (min-width: 768px) {
  .seamlessly__header {
    padding: 7.75rem 0 5.5rem;
  }

  .seamlessly__contents-container {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 1.5rem;
  }
}

@media screen and (min-width: 1024px) {
  .seamlessly__contents-container {
    grid-template-columns: repeat(3, 1fr);
  }

  .seamlessly__content {
    min-height: 26.25rem;
    border-radius: 2rem;
  }

  .seamlessly__content__header {
    padding: 2rem;
  }

  .seamlessly__content__header h3 {
    font-size: 1.5rem;
  }

  .seamlessly__content__body > div {
    border-radius: 1.6875rem;
  }

  .seamlessly__content__body__coin {
    max-width: 7.25rem;
    bottom: 10%;
  }
}

.hero > div {
  background-image: url("https://res.cloudinary.com/fluidcoins/image/upload/v1654873769/useflip-landing/hero-texture._bp4dnu.svg");
  background-repeat: no-repeat;
  background-size: 100%;
  padding: 160px 0 28px;
  position: relative;
  overflow: hidden;
}

.hero-text {
  margin: 0 auto;
  position: relative;
}

.hero-text h1 {
  text-align: center;
  visibility: hidden;
  color: #fff;
  font-size: clamp(2.25rem, 5vw, 4.5rem);
  font-weight: 700;
  line-height: 93%;
}

.hero-text h1 span {
  color: #aeff00;
}

.hero-effect img {
  position: absolute;
}

.hero-effect--star img {
  width: 5.625rem;
  max-width: 7.375rem;
  bottom: 90%;
  left: 0%;
}

.hero-effect--coin img {
  width: 44px;
  max-width: 7.375rem;
  bottom: 110%;
  right: 5%;
}

.hero-effect--trophy img {
  max-width: 6.875rem;
  width: 50px;
  left: 15%;
}

.hero-effect--goggles img {
  max-width: 8.4375rem;
  width: 3.75rem;
  right: 13%;
}

.hero-effect--shadow-coin img {
  display: none;
}

.hero-image {
  width: 100%;
  max-width: 41rem;
  z-index: 1;
  margin: 6rem auto 0;
  padding: 0 1.5rem;
  position: relative;
}

.hero-texture {
  width: 100%;
  height: 100%;
  background-image: url("https://res.cloudinary.com/fluidcoins/image/upload/v1655119057/useflip-landing/hero-background-mobile_pka9nw.svg");
  background-repeat: no-repeat;
  background-size: 100%;
  position: absolute;
  top: 27%;
}

.hero-background {
  width: 100%;
  min-height: 90%;
  background-image: url("https://res.cloudinary.com/fluidcoins/image/upload/v1655118740/useflip-landing/background-coin-mobile_cgetnh.svg");
  background-repeat: no-repeat;
  background-size: 100%;
  position: absolute;
  top: 37%;
}

@media screen and (min-width: 768px) {
  .hero > div {
    padding-top: 16.75rem;
  }

  .hero-image {
    margin-top: 7.625rem;
    padding: 0;
  }

  .hero-texture, .hero-background {
    background-size: 100%;
  }
}

@media screen and (min-width: 1024px) {
  .hero-effect img {
    width: 100%;
  }

  .hero-effect--star img {
    bottom: 90%;
    left: 10%;
  }

  .hero-effect--coin img {
    bottom: 65%;
    right: 13%;
  }

  .hero-effect--trophy img {
    bottom: -40%;
    left: 20%;
  }

  .hero-effect--goggles img {
    bottom: -15%;
    right: 22%;
  }

  .hero-effect--shadow-coin img {
    max-width: 9.625rem;
    z-index: 10;
    display: block;
    bottom: -110%;
    left: 10%;
  }

  .hero-texture {
    background-image: url("https://res.cloudinary.com/fluidcoins/image/upload/v1654871122/useflip-landing/hero-background_mzjwwa.svg");
    top: 25%;
  }

  .hero-background {
    background-image: url("https://res.cloudinary.com/fluidcoins/image/upload/v1655501963/useflip-landing/background-coin_b7sydr.svg");
    top: 36.5%;
  }
}

.Typewriter__cursor {
  display: none;
}

/*# sourceMappingURL=index.1bfd6a22.css.map */
