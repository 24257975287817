// color
$default-background: #000000;
$black: #000000;
$black100: #000;
$grey100: #888888;
$white: #fff;
$secondary: #aeff00;
$tertiary: #d2fa7c;
$tertiary-1: #f89cde;
$primary: #493af0;
$blue: #293ef8;

//font

$momentum-font: "Monument Extended", sans-serif;

// font-weight
$font-weight-1: 400;
$font-weight-2: 500;
$font-weight-3: 600;
$font-weight-4: 700;
$font-weight-5: 800;
$font-weight-6: 900;

$download-effect: 23%;
$download-title-font-size: clamp(42px, 11.2vw, 128px);
$download-title-effect: calc(clamp(42px, 11.2vw, 128px) * 0.45);
