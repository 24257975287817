header {
  position: relative;
  z-index: 1;

  nav {
    padding: 40px 0 0;
    position: absolute;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;

    > a {
      display: inline-flex;
      align-items: center;
      background: $white;
      border-radius: 40px;
      padding: 4px 8px 4px 4px;

      h2 {
        font-family: "ClashDisplay";
        font-weight: $font-weight-2;
        font-size: 20px;
        line-height: 1;
        color: $black100;
      }

      div {
        padding: 8px 0;
        background-color: $black;
        border-radius: 14px;
        margin-right: 10px;
      }
    }

    > div {
      display: none;
    }
  }

  @media screen and (min-width: 768px) {
    nav {
      padding: 90px 105px 0 0;

      &::before {
        content: "";
        display: block;
        flex: 0 0 50%;
      }

      > div {
        display: inline-flex;
        align-items: center;
        padding: 12.5px 16px;
        background-color: $white;
        border-radius: 26.5px;
        margin-left: auto;

        div {
          border-left: 1px solid #e7e7e7;
          margin: 0 12px;
          height: 22px;
        }
      }
    }
  }
}
