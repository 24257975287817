.download {
  background-image: url(https://res.cloudinary.com/fluidcoins/image/upload/v1654873769/useflip-landing/hero-texture._bp4dnu.svg);
  > div {
    min-height: 75vh;
    position: relative;
    overflow: hidden;
  }

  &-main {
    z-index: 1;
    position: relative;

    &__header {
      position: relative;
      padding-top: 9.75rem;

      > div {
        position: relative;
      }

      * {
        font-family: $momentum-font;
        font-weight: $font-weight-5;
        line-height: 1.21;
        font-size: $download-title-font-size;
        min-height: 0vw;
        text-align: center;
      }

      &__title {
        color: $secondary;
        position: relative;
        z-index: 10;

        &--effect {
          position: absolute;
          height: $download-title-effect;
          display: block;
          left: 50%;
          transform: translateX(-50%);
          background: $white;
          -webkit-background-clip: text;
          -webkit-text-stroke: 2px transparent;

          @for $i from 1 through 2 {
            &:nth-child(n + #{$i}) {
              top: -($download-effect * $i);
              z-index: -1 * $i;
            }
          }
        }
      }

      &__effect img {
        position: absolute;
      }
    }

    &__link {
      &-container {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 1.25rem;

        & > :last-child {
          margin-left: 0.5rem;
        }
      }

      &-item {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        background-color: $white;
        padding: 0.25rem 0;
        height: convertToRem(37px);
        font-weight: $font-weight-4;
        font-size: convertToRem(10px);
        line-height: 1.46;
        width: 100%;
        max-width: convertToRem(112px);
        border-radius: convertToRem(32px);

        > span {
          font-family: "Satoshi";
          margin-left: 0.25rem;
        }
      }
    }
  }

  &-texture {
    position: absolute;
    bottom: 0;
    height: 52%;
    width: 100%;
    background-image: url(https://res.cloudinary.com/fluidcoins/image/upload/v1654871876/useflip-landing/download-texture_mctxc5.svg);
    background-size: 200%;
    background-position: bottom;
    background-repeat: no-repeat;
  }

  &-effect {
    &--coin img {
      bottom: 40%;
      left: 5%;
      max-width: convertToRem(156px);
      width: convertToRem(42px);
    }

    &--double-coin {
      &--desktop {
        display: none;
      }

      &--mobile img {
        width: convertToRem(55px);
        bottom: 0%;
        right: 0%;
      }
    }

    &--goggles img {
      bottom: 45%;
      left: 55%;
      max-width: convertToRem(78px);
      width: convertToRem(48px);
    }
  }

  @media screen and (min-width: 768px) {
    > div {
      min-height: min(convertToRem(648px), 90vh);
    }

    &-main {
      &__link {
        &-item {
          max-width: convertToRem(156px);
          height: convertToRem(56px);

          span {
            font-size: convertToRem(14px);
          }
        }
      }
    }

    &-texture {
      background-size: 100%;
      height: 72%;
    }

    &-effect {
      &--coin img {
        bottom: 20%;
        left: 3%;
        width: 11%;
      }

      &--double-coin {
        img {
          width: 14%;
          bottom: 0%;
          right: -2%;
        }
      }

      &--goggles img {
        bottom: 60%;
        width: 11%;
        left: 60%;
      }
    }
  }

  @media screen and (min-width: 1024px) {
    > div {
      min-height: max(convertToRem(1000px), 90vh);
    }

    &-main__header {
      padding-top: convertToRem(260px);
    }

    &-effect {
      &--coin img {
        bottom: 20%;
        left: 3%;
      }

      &--double-coin {
        &--desktop {
          display: block;
        }

        &--mobile {
          display: none;
        }
        img {
          bottom: 0%;
          width: 31%;
          right: -2%;
        }
      }

      &--goggles {
        img {
          bottom: 60%;
          left: 60%;
        }
      }
    }
  }

  @media screen and (min-width: 1360px) {
    &-effect {
      &--coin img {
        left: 8%;
      }

      &--double-coin img {
        right: 6%;
      }
    }
  }
}
