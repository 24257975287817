.footer {
  @include container();
  > div {
    max-width: 832px;
    padding: 89px 0 60px;
  }
  * {
    font-family: "CreatoDisplay";
  }

  &-header {
    padding: 0 1rem;
    margin-bottom: 70px;

    > div {
      display: inline-flex;
      align-items: center;
    }

    &__logo {
      margin-right: 10px;
    }

    &__title {
      font-family: "ClashDisplay";
      font-weight: 500;
      font-size: 20px;
      line-height: 93%;
      color: #ffffff;
    }

    &__mail {
      font-size: 14px;
      display: block;
      line-height: 17px;
      color: #ffffff;
      margin: 38px 0 36px;
    }

    &__address {
      font-style: normal;
      font-size: 14px;
      line-height: 27px;
      color: #ffffff;
    }
  }

  &-nav {
    width: 100%;
    max-width: 600px;
    margin-bottom: 94px;
    padding: 0 1rem;
    display: grid;
    row-gap: 76px;

    > div {
      // display: gr
    }

    &__link-container {
      display: grid;
      row-gap: 32px;
    }

    &__link {
      display: block;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #ffffff;
    }

    &__title {
      font-weight: $font-weight-4;
      font-size: 17px;
      line-height: 20px;
      color: #888888;
      margin-bottom: 20px;
    }
  }
  &-copyright {
    text-align: center;
    font-weight: 500;
    font-size: 1rem;
    padding-bottom: 60px;
    line-height: 19px;
    color: #ffffff;
  }

  @media screen and (min-width: 768px) {
    > div {
      display: flex;
      justify-content: space-between;
      padding-top: 24px;
    }

    &-header {
      width: 100%;
    }

    &-nav {
      grid-template-columns: repeat(2, 1fr);
      > :last-child {
        justify-self: center;
      }
    }

    &-copyright {
      padding-bottom: 96px;
    }
  }
}
