.hero {
  > div {
    position: relative;
    overflow: hidden;
    padding: 160px 0 28px;
    background-image: url(https://res.cloudinary.com/fluidcoins/image/upload/v1654873769/useflip-landing/hero-texture._bp4dnu.svg);
    background-repeat: no-repeat;
    background-size: 100%;
  }

  &-text {
    margin: 0 auto;
    position: relative;

    h1 {
      text-align: center;
      visibility: hidden;
      font-weight: $font-weight-4;
      font-size: clamp(2.25rem, 5vw, convertToRem(72px));
      line-height: 93%;
      color: $white;

      span {
        color: $secondary;
      }
    }
  }

  &-effect {
    img {
      position: absolute;
    }

    &--star img {
      width: convertToRem(90px);
      max-width: convertToRem(118px);
      bottom: 90%;
      left: 0%;
    }

    &--coin img {
      width: 44px;
      max-width: convertToRem(118px);
      bottom: 110%;
      right: 5%;
    }

    &--trophy img {
      left: 15%;
      max-width: convertToRem(110px);
      width: 50px;
    }

    &--goggles img {
      max-width: convertToRem(135px);
      width: convertToRem(60px);
      right: 13%;
    }

    &--shadow-coin img {
      display: none;
    }
  }

  &-image {
    position: relative;
    width: 100%;
    max-width: convertToRem(656px);
    padding: 0 1.5rem;
    margin: 6rem auto 0;
    z-index: 1;
  }

  &-texture {
    position: absolute;
    top: 27%;
    width: 100%;
    background-size: 100%;
    height: 100%;
    background-image: url(https://res.cloudinary.com/fluidcoins/image/upload/v1655119057/useflip-landing/hero-background-mobile_pka9nw.svg);
    background-repeat: no-repeat;
  }

  &-background {
    position: absolute;
    top: 37%;
    width: 100%;
    min-height: 90%;
    background-image: url(https://res.cloudinary.com/fluidcoins/image/upload/v1655118740/useflip-landing/background-coin-mobile_cgetnh.svg);
    background-repeat: no-repeat;
    background-size: 100%;
  }

  @media screen and (min-width: 768px) {
    > div {
      padding-top: convertToRem(268px);
    }

    &-image {
      padding: 0;
      margin-top: convertToRem(122px);
    }

    &-texture {
      background-size: 100%;
    }

    &-background {
      background-size: 100%;
    }
  }

  @media screen and (min-width: 1024px) {
    &-effect {
      img {
        width: 100%;
      }

      &--star img {
        bottom: 90%;
        left: 10%;
      }

      &--coin img {
        bottom: 65%;
        right: 13%;
      }

      &--trophy img {
        bottom: -40%;
        left: 20%;
      }

      &--goggles img {
        bottom: -15%;
        right: 22%;
      }

      &--shadow-coin img {
        display: block;
        max-width: convertToRem(154px);
        left: 10%;
        bottom: -110%;
        z-index: 10;
      }
    }

    &-texture {
      top: 25%;

      background-image: url(https://res.cloudinary.com/fluidcoins/image/upload/v1654871122/useflip-landing/hero-background_mzjwwa.svg);
    }

    &-background {
      top: 36.5%;
      background-image: url(https://res.cloudinary.com/fluidcoins/image/upload/v1655501963/useflip-landing/background-coin_b7sydr.svg);
    }
  }
}

.Typewriter__cursor {
  display: none;
}
