.spending {
  position: relative;
  background-color: $white;
  min-height: 100vh;
  padding: 4.5rem 0 2.375rem;
  display: flex;
  align-items: center;

  > div {
    width: 100%;
  }

  &__main {
    @include container();
    position: relative;
    width: 100%;
    margin: 0 auto;
    padding: 0 1.5rem;
    z-index: 1;

    > div {
      display: flex;
      flex-direction: column-reverse;
    }

    &__right {
      &__tag {
        background: $blue;
        width: fit-content;
        padding: 1.125rem;
        border: 0.4375rem solid #ffffff;
        border-radius: convertToRem(61px);
        transform: rotate(-2.87deg);

        p {
          font-family: $momentum-font;
          font-weight: $font-weight-5;
          font-size: 1.0625rem;
          line-height: 1.4;
          text-transform: capitalize;
          color: $white;
        }
      }

      &__title {
        margin-top: 2rem;

        h2 {
          font-family: $momentum-font;
          font-weight: $font-weight-5;
          font-size: 2.25rem;
          line-height: 106%;
          text-transform: capitalize;
          color: $black100;
        }

        br {
          display: none;
        }
      }

      &__features {
        margin-top: 4rem;
        max-width: convertToRem(365px);

        > div:first-child {
          margin-bottom: 2.875rem;
        }

        div:last-child {
          margin-bottom: 5.75rem;
        }

        div {
          h2 {
            font-weight: $font-weight-3;
            line-height: 93%;
            font-size: 1.125rem;
            color: $black100;
          }

          p {
            font-weight: $font-weight-2;
            font-size: 0.9375rem;
            line-height: 120%;
            color: $grey100;
            margin-top: 1.25rem;
          }

          a {
            display: block;
            font-size: 0.625rem;
            font-family: "Satoshi";
            font-weight: $font-weight-4;
            text-align: center;
            background-color: $black;
            color: $white;
            padding: 0.875rem 0;
            line-height: 1;
            margin-top: 2.5rem;
            max-width: convertToRem(118px);
            border-radius: 1.5rem;
          }
        }
      }
    }

    &__image img {
      margin: 0 auto;
    }
  }

  &__hover-reveal {
    display: none;
  }

  &__texture {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 70%;
    background-image: url(https://res.cloudinary.com/fluidcoins/image/upload/v1655110856/useflip-landing/spending-texture-mobile_rsjijl.svg);
    background-repeat: no-repeat;
    background-size: cover;
  }

  @media screen and (min-width: 768px) {
    &__main {
      &__right {
        &__tag {
          padding: 26px;
          border: 0.4375rem solid #ffffff;
          border-radius: convertToRem(61px);
          transform: rotate(-8.95deg);
          p {
            font-size: 1.375rem;
          }
        }

        &__title {
          font-size: 2.75rem;

          br {
            display: block;
          }
        }

        &__features {
          margin-top: 2.75rem;

          > div:first-child {
            margin-bottom: 3.25rem;
          }

          > div {
            h2 {
              font-size: 1.25rem;
            }

            p {
              color: $black100;
              margin-top: 1.5rem;
              font-size: 1.125rem;
            }

            a {
              margin-top: 2.75rem;
              font-size: 0.875rem;
              max-width: convertToRem(172px);
              padding: 1.19rem 0;
              border-radius: 2.5rem;
            }
          }
        }
      }
    }
  }

  @media screen and (min-width: 768px) {
    &__main {
      &__image {
        img {
          height: calc(100vh - 7rem);
        }
      }
    }
  }

  @media screen and (min-width: 1024px) {
    &__main {
      &__right__features {
        > div:last-child {
          margin-bottom: 0px;
        }
      }

      > div {
        flex-direction: row;
        padding-left: 1.25rem;
        padding-right: convertToRem(205px);
        justify-content: space-between;
      }

      &__image img {
        max-height: convertToRem(870px);
      }
    }

    &__texture {
      height: 37vw;
      background-image: url(https://res.cloudinary.com/fluidcoins/image/upload/v1654886746/useflip-landing/spending-texture_p1msoz.svg);
    }

    &__hover-reveal {
      display: block;
      position: absolute;
      top: 20%;
      user-select: none;
      pointer-events: none;
      width: convertToRem(220px);
      height: convertToRem(150px);

      &__inner__img {
        width: 100%;
        height: 100%;
      }
    }

    @media screen and (min-height: 800px) {
      &__texture {
        height: 45vw;
      }
    }
  }
}
