@charset "UTF-8";
// 1. Configuration and helpers
@import
  'abstracts/variables',
  'abstracts/functions',
  'abstracts/mixins';

// 2. Vendors
// @import
//   'vendors/normalize';

// 3. Base stuff
@import
  'base/base',
  'base/fonts',
  'base/typography',
  'base/locomotive',
  'base/helpers';

// 4. Layout-related sections
@import
  'layouts/footer',
  'layouts/header';

// 5. Components
@import
  'components/button',
  'components/download',
  'components/form',
  'components/preloader',
  'components/features',
  'components/savings',
  'components/spending',
  'components/seamlessly',
  'components/hero';

// 6. Page-specific styles
@import
  'pages/home';

// 7. Themes
@import
  'themes/default';
