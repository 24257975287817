.seamlessly {
  // min-height: 100vh;

  > div {
    @include container();
    padding: 0 1rem;
  }

  &__header {
    padding: 5rem 0 3.5rem;

    h2 {
      font-family: $momentum-font;
      font-weight: $font-weight-5;
      font-size: clamp(2.25rem, 4.76vw, convertToRem(72px));
      line-height: 1.06;
      text-align: center;
      text-transform: capitalize;
      color: $secondary;
    }
  }

  &__contents-container {
    display: grid;
    row-gap: 2.75rem;
  }

  &__content {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    border-radius: 1.625rem;
    min-height: convertToRem(345px);
    overflow: hidden;

    img {
      width: 100%;
    }

    &__header {
      padding: 1.5rem;

      h3 {
        font-weight: $font-weight-3;
        font-size: 1.25rem;
        line-height: 106%;
        text-transform: capitalize;
      }
    }

    &__body {
      padding: 0 0.5rem 0.5rem;
      margin-top: 0.25rem;
      height: 100%;

      > div {
        position: relative;
        height: 100%;
        border: 0.8px solid rgba(255, 255, 255, 0.32);
        border-radius: 1.375rem;
        background: rgba(255, 255, 255, 0.38);

        img {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
        }
      }
      &__coin {
        bottom: 7.5%;
        max-width: 90px;
      }

      &__qr-code {
        max-width: 90%;
        top: -28%;
      }

      &__earth {
        top: -30%;
      }
    }
  }

  &__gradient--flip-tag {
    background-image: url(https://res.cloudinary.com/fluidcoins/image/upload/v1655121029/useflip-landing/flip-tag-radient_py1zmu.webp);
    background-repeat: no-repeat;
    background-size: cover;
  }

  &__gradient--seamlessly {
    background-image: url(https://res.cloudinary.com/fluidcoins/image/upload/v1654873477/useflip-landing/seamless-gradient_hyhzlw.webp);
    background-repeat: no-repeat;
    background-size: cover;
  }

  &__gradient--payouts {
    background-image: url(https://res.cloudinary.com/fluidcoins/image/upload/v1654873477/useflip-landing/payouts-gradient_tzgijb.webp);
    background-repeat: no-repeat;
    background-size: cover;
  }

  @media screen and (min-width: 768px) {
    &__header {
      padding: 7.75rem 0 5.5rem;
    }

    &__contents-container {
      grid-template-columns: repeat(2, 1fr);
      column-gap: 1.5rem;
    }
  }

  @media screen and (min-width: 1024px) {
    &__contents-container {
      grid-template-columns: repeat(3, 1fr);
    }

    &__content {
      border-radius: 2rem;
      min-height: convertToRem(420px);

      &__header {
        padding: 2rem;

        h3 {
          font-size: 1.5rem;
        }
      }

      &__body {
        > div {
          border-radius: 1.6875rem;
        }

        &__coin {
          bottom: 10%;
          max-width: convertToRem(116px);
        }
      }
    }
  }
}
