.savings {
  background-color: $primary;
  padding: convertToRem(80px) 0 convertToRem(40px);
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;

  &-main {
    @include container();
    position: relative;
    z-index: 1;

    &-left {
      &__tag {
        padding: 1rem 1.5rem;
        transform: rotate(-8.32deg);
        border-radius: 50%;
        background-color: $tertiary-1;
        width: fit-content;

        p {
          font-family: $momentum-font;
          font-weight: $font-weight-5;
          line-height: 1.01;
          letter-spacing: 0.07em;
          text-transform: capitalize;
          color: $tertiary;
        }
      }

      &__title {
        margin-top: 1.5rem;

        h2 {
          font-family: $momentum-font;
          font-weight: $font-weight-5;
          font-size: convertToRem(36px);
          line-height: 1.06;
          text-transform: capitalize;
          color: $white;
        }
      }

      &__features {
        margin-top: convertToRem(78px);
        display: grid;
        gap: 2.75rem;

        div {
          h3 {
            margin-top: 0.75rem;
            font-family: "CreatoDisplay";
            font-weight: $font-weight-3;
            font-size: 18px;
            line-height: 1.3;
            text-transform: capitalize;
            color: $white;
          }

          br {
            display: none;
          }
        }
      }
    }

    &__image {
      margin-top: 8rem;

      img {
        margin: 0 auto;
      }
    }
  }

  &__hover-reveal {
    display: none;
  }

  &-texture {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: min(100vh, convertToRem(960px));
    background-image: url(https://res.cloudinary.com/fluidcoins/image/upload/v1655063774/useflip-landing/saving-texture-mobile_efkj66.svg);
    background-size: 100%;
    background-position: bottom;
    background-repeat: no-repeat;
  }

  @media screen and (min-width: 768px) {
    &-main__image {
      img {
        height: calc(100vh - 7rem);
      }
    }
  }

  @media screen and (min-width: 1024px) {
    min-height: 100vh;
    padding: 3.5rem 0;

    &-main {
      padding-right: 5rem;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &-left {
        &__tag {
          padding: 1.25rem 2rem;
          transform: rotate(-8.32deg);
          border-radius: 50%;

          P {
            font-size: 2.625rem;
          }
          &__title {
            margin-top: 3rem;

            h2 {
              font-size: 2.75rem;
            }
          }
        }
        &__features {
          gap: 56px;
          grid-template-columns: repeat(2, minmax(convertToRem(300px), 1fr));

          div h3 {
            font-size: 1.25rem;

            br {
              display: block;
            }
          }
        }
      }

      &__image {
        margin-top: 0px;

        img {
          max-height: convertToRem(870px);
        }
      }
    }

    &-texture {
      left: 0;
      background-image: url(https://res.cloudinary.com/fluidcoins/image/upload/v1654882240/useflip-landing/savings-texture_k0rpla.svg);
      background-size: cover;
      background-position: unset;
    }

    &__hover-reveal {
      display: block;
      position: absolute;
      top: 20%;
      width: convertToRem(220px);
      height: convertToRem(220px);

      &__inner__img {
        width: 100%;
        height: 100%;
        pointer-events: none;
        user-select: none;
      }
    }
  }

  @media screen and (min-width: 1536px) {
    &-texture {
      height: 100vh;
    }
  }
}
